<script lang="ts" setup>
import type { SelectField } from '~/types/storyblok/shared'

interface Props {
  field: SelectField
  state: {
    value: string
    isError: boolean
  }
  bgColor?: string
}
const props = withDefaults(defineProps<Props>(), {
  bgColor: 'white',
})

interface SelectFieldOption {
  value: string
  text: string
}

/**
 * TODO: Move to selectfield normalizer
 * Either the the options are key,key or key:value, so we split them by comma
 * @example 'option1,option2,option3'
 * @example 'option1:Option 1,option2:Option 2,option3:Option 3'
 */
function extractOptions(options: string): SelectFieldOption[] {
  function extractOption(option: string): SelectFieldOption {
    const [value, text] = option.split(':')
    return {
      value,
      text: text || value,
    }
  }

  return options.split(',').map(extractOption)
}

const options = computed<SelectFieldOption[]>(() => extractOptions(props.field.options || ''))
const isOpen = ref<boolean>(false)

const { selectOptionLabelColor, selectedOptionBgColor, hoveredOptionBgColor, errorColor } = useFormColors(props.bgColor ?? 'white')

const { bgInvertedColor } = useBackgroundInvertedColor(props.bgColor ?? 'white')

const ariaError = computed(() => props.state.isError ? props.field.validation_error : '')

function onOpen(open: boolean) {
  isOpen.value = open
}
const select = ref<{ $el: HTMLButtonElement } | null>(null)
function focusField() {
  select?.value?.$el?.focus()
}

defineExpose({
  focusField,
})

</script>

<template>
  <div class="select-field" :class="{ 'select-field--open': isOpen }">
    <label class="label">
      {{ props.field.name }}
      <SelectRoot v-model="props.state.value" @update:modelValue="props.state.isError = false" @update:open="onOpen">
        <SelectTrigger class="trigger" tabindex="0" :aria-invalid="props.state.isError" :aria-label="ariaError" ref="select">
          <SelectValue :placeholder="props.field.placeholder" />
          <SelectIcon>
            <IconBasic class="icon" :filename="isOpen ? 'chevron-up' : 'chevron-down'" />
          </SelectIcon>
        </SelectTrigger>
        <SelectPortal style="width: 100%;">
          <SelectContent
            position="popper" side="bottom" :avoid-collisions="false" :side-offset="5"
            style="z-index: 19; max-height: var(--radix-select-content-available-height); width: var(--radix-select-trigger-width);"
          >
            <SelectScrollUpButton class="scroll">
              <IconBasic class="icon" filename="chevron-up" color="black" />
            </SelectScrollUpButton>
            <SelectViewport style="border-right: 2px solid black; border-left: 2px solid black;">
              <SelectItem
                v-for="(option) in options" :key="option.value" :value="option.value" class="option"
                :class="{ 'option--selected': option.value === props.state.value }" :data-test="`form-field-select-${option}`"
                :style="{ '--bg--inverted-color': bgInvertedColor, '--bg-color': bgColor, '--label-color': selectOptionLabelColor, '--option-select': selectedOptionBgColor, '--option-hover': hoveredOptionBgColor }">
                <SelectItemText>
                  {{ $t(option.text) }}
                </SelectItemText>
              </SelectItem>
            </SelectViewport>
            <SelectScrollDownButton class="scroll">
              <IconBasic class="icon" filename="chevron-down" color="black" />
            </SelectScrollDownButton>
          </SelectContent>
        </SelectPortal>
      </SelectRoot>
    </label>
    <p v-show="props.state.isError" class="error">
      {{ props.field.validation_error }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/rules/breakpoints';
@import '~/assets/scss/typography/body';

.select-field {
  width: 100%;

  &--open {
    .trigger {
      background-color: var(--white);
      color: v-bind(bgInvertedColor);
      border: 1px solid transparent;
      outline-offset: -1px;
      outline: 2px solid v-bind(bgInvertedColor);
      background-color: v-bind(bgColor);

      &__icon {
        background-color: var(--black);
      }
    }
  }
}

.trigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  gap: 1rem;
  border-radius: 2px;
  width: 100%;
  border: 1px solid v-bind(bgInvertedColor);
  height: 4rem;

  &:focus {
    border: 1px solid transparent;
    outline: 2px solid v-bind(bgInvertedColor);
    outline-offset: -1px;
  }
}

.option {
  background-color: var(--bg--inverted-color);
  color: var(--label-color);
  padding: 1.2rem;
  height: 4rem;
  border-bottom: 1px solid var(--label-color);
  cursor: pointer;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  outline-offset: -2px;
  width: 100%;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: var(--option-hover);
    color: var(--black);
    outline: none;
  }

  &:hover,
  &:focus,
  &:focus-visible.option--selected {
    background-color: var(--option-hover);
  }

  &:first-child {
    border-radius: 2px 2px 0 0;
    border-top: 2px solid var(--bg--inverted-color);

    &:hover,
    &:focus {
      border-top: 2px solid var(--black);
    }
  }

  &:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: 2px solid var(--bg--inverted-color);

    &:hover,
    &:focus {
      border-bottom: 2px solid var(--black);
    }
  }

  &--selected {
    background-color: var(--option-select);
    color: var(--black);
  }
}

.scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  background-color: white;
  border: 1px solid var(--black);
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid transparent;
    outline: 2px solid var(--black);
    outline-offset: -1px;
  }
}

.label {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
}

.error {
  font-size: 1.2rem;
  margin-top: 1.1rem;
  color: v-bind(errorColor);
}
</style>
