<script lang="ts" setup>
import { emailRegex } from 'data/patterns/email'
import type { SignupSources } from '~/types/gtm'
import type { SelectField, TextField } from '~/types/storyblok/shared'

interface Props {
    fields: (TextField | SelectField)[]
    legal: string
    label: string
    bgColor?: string
    signupSource: SignupSources
    identifier?: string
}

const props = withDefaults(defineProps<Props>(), {
    bgColor: 'white',
})

const emit = defineEmits(['success', 'error'])

interface FocusableComponent {
  focusField: () => void
}

const storefrontStore = useStorefrontStore()
const gtm = useGTM()
const inputRefs = ref<{ [key: string]: FocusableComponent | null }>({});
const { bgInvertedColor } = useBackgroundInvertedColor(props.bgColor ?? 'white')

const { buttonLabelColor, buttonHoverColor } = useFormColors(props.bgColor ?? 'white')

onMounted(() => {
  setTimeout(() => {
    inputRefs.value[props.fields[0].id]?.focusField()
  }, 100)
})

interface Form {
  loading: boolean
  fields: {
    [key: string]: {
      value: string
      isError: boolean
    }
  }
}

const formState = reactive<Form>({
  loading: false,
  fields: props.fields.reduce((acc, field) => {
    acc[field.id] = {
      value: '',
      isError: false,
    }
    return acc
  }, {} as Form['fields']),
})

// There are several validating functions in this codebase. They should be combined and refactored or replaced with a library in the future.

function validate() {
  let isValid = true
  let isFieldFocused = false
  for (const field of props.fields) {
    if (field.component === 'text-field' && field.is_email_input_type) {
      if (!emailRegex.test(formState.fields[field.id].value)) {
        formState.fields[field.id].isError = true
        isValid = false

        if (!isFieldFocused) {
          inputRefs.value[field.id]?.focusField()
          isFieldFocused = true
        }
      }
      else {
        formState.fields[field.id].isError = false
      }
    }
    else if (!formState.fields[field.id].value) {
      formState.fields[field.id].isError = true
      isValid = false

      if (!isFieldFocused) {
        inputRefs.value[field.id]?.focusField()
        isFieldFocused = true
      }
    }
    else {
      formState.fields[field.id].isError = false
    }
  }
  return isValid
}
async function subscribe() {
  if (validate()) {
    formState.loading = true
    await sendForm()
    formState.loading = false
  }
}

async function sendForm() {
  const body: Record<string, string> = {
    store: storefrontStore.currentStorefrontCode,
    countryCode: storefrontStore.currentMarketCountryCode,
  }
  for (const field of props.fields) {
    body[field.id] = formState.fields[field.id].value
  }
  const { error } = await useFetch('/api/newsletter', {
    method: 'POST',
    body,
  })

  if (!error.value) {
    emit('success')
  }
  else {
    emit('error')
  }

  const trackableFields = Object.entries(body).reduce((acc, [key, value]) => {
    const field = props.fields.find(field => field.id === key)

    if (field && !field.exclude_field_in_tracking) {
      acc[key] = value
    }

    return acc
  }, {} as Record<string, string>)

  gtm.pushNewsletterEvent({
    eventLabel: !error.value ? 'success' : 'fail',
    email: formState.fields.email.value,
    fields: trackableFields,
    signupSource: props.signupSource,
    identifier: props.identifier || '',
  })
}
</script>

<template>
    <form v-if="fields" class="form" :style="{ backgroundColor: bgColor }">
        <template v-for="field in fields" :key="field.id">
            <FormTextField v-if="field.component === 'text-field'" :field="field" :state="formState.fields[field.id]"
                :ref="(el) => (inputRefs[field.id] = el as unknown as FocusableComponent)" :bg-color="bgColor" />
            <FormSelectField v-if="field.component === 'select-field'" :field="field"
                :state="formState.fields[field.id]"
                :ref="(el) => (inputRefs[field.id] = el as unknown as FocusableComponent)" :bg-color="bgColor" />
        </template>
        <div v-if="legal" class="legal" v-html="legal" />
        <ButtonBasic v-if="label" class="subscribe" role="button" design="no-border" size="medium" :uppercase="false"
            :background-color="bgInvertedColor" :color="buttonLabelColor" :hoverColor="buttonHoverColor"
            @click="subscribe" data-test="segmented-popup-subscribe">
            {{ label }}
        </ButtonBasic>
    </form>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;

    &:deep(a:focus-visible) {
        outline: 2px solid v-bind(bgInvertedColor);
    }
}

.subscribe:focus-visible {
    outline: 2px solid v-bind(bgInvertedColor);
}
</style>
